import Home from './home'
import Navbar from './navbar';
import About from './about'
import { useState } from 'react'
import 'bootstrap/dist/js/bootstrap.bundle'
/*
menu: Todos los elementos de *menu* se mostraran en el navbar
  label: Es lo que se muestra finalmente en el html, en el futuro se puede utilizar para i18n
  name:  Es el identificador unico de la pagina
  url:   No se utiliza de momento
*/
let menu = [{ label: 'Inicio', name: 'home', url: '/home' },
{ label: 'Acerca de', name: 'about', url: '/about' }
]
/*
pages:       Lista de paginas disponibles para mostrar
  name:      Identificador unico de pagina
  component: Componente que renderiza la pagina
*/

//let activePage = pages[0]

//function setPage(req_name) {
//  activePage = pages.find(({ name }) => name === req_name)
//}

function Layout(props) {
  const pages = [{ name: 'home', component: <Home /> },
{ name: 'about', component: <About /> }]
  const [activePage, setActivePage] = useState(pages[0]);

  const handleClick = (e, req_name) => {
    //console.log('recibimos '+req_name,e)
    let p = pages.find(({ name }) => name === req_name)    
    setActivePage(p)
  }

  return (
    <>
      <header>    
        <Navbar items={menu} handleClick={handleClick} />
      </header>
      <main>
        <>{activePage.component}</>
      </main>
    </>
  );
}

export default Layout;