
function About() {
    return (
      <div>     
      <div className="p-5 mb-4 rounded-3">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold"></h1>
          <p className="col-md-8 fs-4"></p>
        </div>
        </div>
    </div>
    );
  }
  
  export default About;
  