import logo from './logo.svg';
import './App.css';
import Layout from './pages/layout';

function App() {
  return (
    <Layout />
  );
}

export default App;
